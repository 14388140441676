import React from "react";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

import { Spinner } from "react-bootstrap";

const PreviewPage = ({ isPreview, ...props }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <div className="text-center py-5">
      <Spinner animation="grow" variant="primary" />
      <p className="h2">Loading preview</p>
    </div>
  );
};

export default withPrismicPreviewResolver(PreviewPage);
